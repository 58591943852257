/**
 * @file Punto de entrada para configurar sentry. Es un pack aparte para poder cargarlo asincronamente
 */
import * as Sentry from '@sentry/browser';
import {
  extraErrorDataIntegration,
  contextLinesIntegration
} from '@sentry/integrations';
import { memoize } from 'lodash-es';

window.Sentry = Sentry;

const ignoredIC = new Set([
  401, // lo vamos a ver por el lado del servidor
  502, // lo vamos a ver por el lado del servidor
  403, // lo vamos a ver por el lado del servidor
  500, // lo vamos a ver por el lado del servidor
  503, // lo vamos a ver por el lado del servidor
  504, // lo vamos a ver por el lado del servidor
  0, // es cuando el xhr no se completo: se cancelo por alguna razon (se navega, por ejemplo)
]);

function processIntercoolerError(_ev, elt, status, str, xhr) {
  const url = xhr.responseURL || elt.attr('ic-src') || elt.attr('ic-post-to') || elt.attr('ic-get-from');
  if (!ignoredIC.has(xhr.status)) {
    Sentry.captureMessage(`Intercooler request to ${url} failed with status ${xhr.status} ${status}: ${str}`);
  }
}

function processDataTablesError(_ev, settings, techNote, message) {
  Sentry.captureMessage(`DataTables error: ${message}`, {
    extra: { techNoteUrl: `https://datatables.net/manual/tech-notes/${techNote}`, TableId: settings.sTableId } });
}

/**
 * Captura información extra de los ErrorEvent para incluir en su
 */
class ExtraEventData {

  /**
   * @inheritDoc
   */
  static id = 'ExtraEventData';

  /**
   * @inheritDoc
   */
  get name() {
    return ExtraEventData.id;
  }

  /**
   * @inheritdoc
   */
  setupOnce(addGlobalEventProcessor, getCurrentHub) {
    addGlobalEventProcessor((event, hint) => {
      const self = getCurrentHub().getIntegration(ExtraEventData);
      if (!self) {
        return event;
      }
      return self.enhanceEventWithEventData(event, hint);
    });
  }

  enhanceEventWithEventData(event, hint) {
    const exception = hint.originalException;
    if (!exception || exception.type !== 'error') {
      return event;
    }

    const props = ['message', 'filename', 'lineno', 'colno', 'error'];
    const errorEventData = {};
    props.forEach(prop => {
      if (prop in exception) {
        errorEventData[prop] = exception[prop];
      }
    });

    if (Object.keys(errorEventData).length === 0) {
      return event;
    }

    // No se porque hay que copiarlo, pero saque esto de ExtraErrorData
    const contexts = { ...event.contexts, ErrorEvent: errorEventData };
    return { ...event, contexts };
  }
}

function processError(oldError) {
  return function processErrorImpl(ev) {
    const argsArray = Array.prototype.slice.call(arguments);
    // Hay eventos que no calzan con lo que espera sentry, asi
    // que los reformateamos
    switch (ev.namespace) {
      case 'ic':
        processIntercoolerError.apply(this, argsArray);
        break;
      case 'dt':
        processDataTablesError.apply(this, argsArray);
        break;
      default:
        oldError.apply(this, argsArray);
    }
  };
}

function getSentryData() {
  const jsonSrc = document.getElementById('sentry-data')?.innerText;
  if (!jsonSrc) {
    return;
  }
  return JSON.parse(jsonSrc);
}

/**
 *
 * @param {Sentry.Event} event
 * @param {Sentry.EventHint} hint
 */
function beforeSend(event, hint) {
  // Ignoramos promesas rechazadas que vienen sin información. No tenemos mucho que hacer aca
  for (const values of event.exception?.values || []) {
    if (values.mechanism?.type === 'onunhandledrejection' && typeof(hint.originalException) === 'string') {
      if (hint.originalException === '') {
        // Esto parece ser una extensión de login
        // https://github.com/getsentry/sentry-javascript/issues/3440
        return null;
      }
      if (hint.originalException === 'Timeout') {
        // Esto parece ser recaptcha
        // https://github.com/getsentry/sentry-javascript/issues/2514
        return null;
      }
      if (hint.originalException.startsWith('Object Not Found Matching Id:')) {
        // ni idea que es pero tira harta pinta de ser lo mismo que el primero, pero en CEF
        // https://github.com/cefsharp/CefSharp/blob/master/CefSharp/Internals/JavascriptObjectRepository.cs#L293
        return null;
      }
    }
    else if (
      hint.originalException?.message?.match(/Illegal invocation|t\.postMessage is not a function/) &&
      values.stacktrace.frames?.some((element) => element?.filename?.match(/freshchat.*widget.js/))
    ) {
      return null;
    }
  }
  return event;
}

Sentry.init({
  dsn: 'https://726f92d61ae44efe9ff0b30473ab8a0d@sentry.io/174682',
  environment: getSentryData()?.environment,
  beforeSend: beforeSend,
  release: process.env.CI_COMMIT_SHA,
  denyUrls: [
    'file://',
    'localhost',
    '127.0.0.1',
    '/mini-profiler-resources/includes.js',
    '/packs/js/ckeditor.js',
    /.*\.akamaihd\.net\/loaders/, // Un malware aparentemente
  ],
  ignoreErrors: [
    "TypeError: null is not an object (evaluating '__gCrWeb.form.wasEditedByUser.set')",
    // Safari en iOS parece que tiene este bug... https://twitter.com/levelsio/status/1187624477795835904
    "TypeError: null is not an object (evaluating 'document.head.querySelector(\"meta[name='supported-color-schemes']\").content')",
    "Can't execute code from a freed script",
    'No se puede ejecutar código de un script liberado', // Errores en español...
    "TypeError: Cannot read property 'isBranch' of null", // https://github.com/riophae/vue-treeselect/pull/208
    'undefined',
    undefined,
    // Por si el pc del cliente está pidiendo perdon...
    /No hay suficientes recursos de memoria disponibles para completar esta operación/,
    /Not enough memory resources are available to complete this operation/,
    // Estos errores no tenemos como arreglarlos
    /Error interno en las Extensiones de Microsoft para Internet/,
    /Intento de operación ilegal en una clave del Registro que estaba marcada para su eliminación/,
    // Estos ocurren cuando la gente guarda el html en su computador y lo abre
    /SecurityError: Failed to execute 'replaceState' on 'History'.*URL 'file:\/\//,
    /SecurityError: Failed to execute 'pushState' on 'History'.*URL 'file:\/\//,
    /ChunkLoadError.*file:\/\//,
    "Can't find variable: instantSearchSDKJSBridgeClearHighlight",
    // Las extensiones de chrome estan arojando sentrys asociados a bloqueos de CSP, lo cual es correcto que suceda
    /chrome-extension:\/\//,
    /Refused to evaluate a string as JavaScript because 'unsafe-eval'/,
    // Es un error del navegador firefox mozilla. https://bugzilla.mozilla.org/show_bug.cgi?id=1484595
    'NS_ERROR_NOT_INITIALIZED',
  ],
  integrations: [
    new ExtraEventData(),
    extraErrorDataIntegration({ depth: 5 }),
    contextLinesIntegration(),
  ],
});

/** document.body no siempre existe cuando se parsea el js. En ese caso, esperamos un poco */
function setDocumentBodyHandler() {
  if (document.body) {
    // Esto debe ser despues de init para que esten seteados los handlers
    // Notificamos solamente una vez cada error
    const newHandler = memoize(processError(document.body.onerror));
    window.onerror = newHandler;
    document.body.onerror = newHandler;
  }
  else {
    setTimeout(setDocumentBodyHandler, 1);
  }
}
setDocumentBodyHandler();

window.addEventListener('DOMContentLoaded', () => {
  // Ignoramos los errores: los capturamos via el processError
  // En desarrollo sigue mostrandose el alert porque no se incluye este js
  if (window.$?.fn?.dataTable?.ext) {
    window.$.fn.dataTable.ext.errMode = () => {};
  }
});

function setTags() {
  const data = getSentryData();
  if (data) {
    Sentry.setUser(data.user);
    Sentry.setTags(data.tags);
  }
}

document.addEventListener('turbolinks:load', setTags);
setTags();
